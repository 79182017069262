@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Russo+One:100,200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Pacifico:100,200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,100,300,500,600,700);
@import url(https://fonts.googleapis.com/css?family=PT+Sans:100,200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One:100,200,300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700);

$font-family-firm:  Montserrat;
$font-family-small: Roboto, sans-serif;

$font-family: 'Russo One', sans-serif;

$font-family: 'Circe', sans-serif;

$font-family: 'Pacifico', cursive;

$font-family: 'Open Sans', sans-serif;

$font-family: 'PT Sans', sans-serif;

$font-family: 'Fredoka One', sans-serif; // !!!!!!!!!!!!!!!!!

$font-family: 'Raleway', sans-serif;

// USE AS MAIN FONT !!! =========== ENGLISH ======
$openSans: 'Open Sans', Helvetica, Arial, sans-serif;