html, body {
  margin: 0;
  padding: 0;
  font-family: ‘Open Sans’, sans-serif;
  font-size: 18px;
}

div {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 12px;
}

h1 {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  margin: 0 0 0;
  padding: 20px 30px;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  margin: 0 0 24px;
  padding: 0 30px;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  margin: 0 0 24px;
  padding: 0 30px;
  text-align: center;
  /*text-transform: uppercase;*/
}

h4 {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 0 12px;
  padding: 0 10px;
  text-align: center;
  /*text-transform: uppercase;*/
}

h5 {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 6px;
  padding: 0 6px;
  text-align: center;
}

h6 {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 0 6px;
  padding: 0 6px;
  text-align: center;
}

p {
  color: #111;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin: 0 0 26px;
}

a {
  color: #dc0400;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline
}

button {
  border: none;
  outline: none;
  background: none;

  cursor: pointer;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 22px;
  }
  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    margin: 0 0 0;
    padding: 20px 20px;
  }
  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 0;
    padding: 20px 20px;
  }
  li {
    font-size: 16px;
  }
}


/* ============ MARKDOWN =============== */


@font-face {
  font-family: Circe;
  src: url(assets/fonts/Circe-Thin/Circe-Thin.eot);
  src: url(assets/fonts/Circe-Thin/Circe-Thin.eot?#iefix)
  format('embedded-opentype'),
  url(assets/fonts/Circe-Thin/Circe-Thin.woff) format('woff'),
  url(assets/fonts/Circe-Thin/Circe-Thin.ttf) format('truetype');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Circe;
  src: url(assets/fonts/Circe-ExtraLight/Circe-ExtraLight.eot);
  src: url(assets/fonts/Circe-ExtraLight/Circe-ExtraLight.eot?#iefix)
  format('embedded-opentype'),
  url(assets/fonts/Circe-ExtraLight/Circe-ExtraLight.woff) format('woff'),
  url(assets/fonts/Circe-ExtraLight/Circe-ExtraLight.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Circe;
  src: url(assets/fonts/Circe-Light/Circe-Light.eot);
  src: url(assets/fonts/Circe-Light/Circe-Light.eot?#iefix)
  format('embedded-opentype'),
  url(assets/fonts/Circe-Light/Circe-Light.woff) format('woff'),
  url(assets/fonts/Circe-Light/Circe-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Circe;
  src: url(assets/fonts/Circe-Regular/Circe-Regular.eot);
  src: url(assets/fonts/Circe-Regular/Circe-Regular.eot?#iefix)
  format('embedded-opentype'),
  url(assets/fonts/Circe-Regular/Circe-Regular.woff) format('woff'),
  url(assets/fonts/Circe-Regular/Circe-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Circe;
  src: url(assets/fonts/Circe-Bold/Circe-Bold.eot);
  src: url(assets/fonts/Circe-Bold/Circe-Bold.eot?#iefix)
  format('embedded-opentype'),
  url(assets/fonts/Circe-Bold/Circe-Bold.woff) format('woff'),
  url(assets/fonts/Circe-Bold/Circe-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Circe;
  src: url(assets/fonts/Circe-ExtraBold/Circe-ExtraBold.eot);
  src: url(assets/fonts/Circe-ExtraBold/Circe-ExtraBold.eot?#iefix)
  format('embedded-opentype'),
  url(assets/fonts/Circe-ExtraBold/Circe-ExtraBold.woff) format('woff'),
  url(assets/fonts/Circe-ExtraBold/Circe-ExtraBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 800;
}