@import "../../assets/constants";
@import "../../assets/colors";
@import "../../assets/fonts";

$margin_content_left: 200px;
$width_group_info_block: 400px;

.choose_stat_type_block, .choose_stat_type_block_second  {
  position: fixed;
  width: $margin_content_left;
  top: $header_height;
  bottom: 0;
  left: 0;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;//rgba(0, 0, 0, 0);
  //background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);

  box-shadow: 0 0 10px rgba(0,0,0,0.1);

  z-index: 11;
}

.choose_stat_type_block_second {
  left: $margin_content_left - $width_group_info_block;
  width: $width_group_info_block;
  transition: left 200ms ease-out;
  z-index: 11;
}

.active_second_block {
  left: $margin_content_left;
}

.full_screen_shadow {
  position: fixed;
  top: $header_height;
  bottom: 0;
  left: 0;
  //right: 0;
  width: 100vw;
  transform: scale(0);
  transition: background-color 200ms linear, transform 0s ease-out;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  //z-index: 1005500;
}

.full_scale {
  //transition: background-color 1000ms linear, transform 0s ease-out 1000ms;
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1);
}

.choose_stat_type {
  height: 100%;
  width: 100%;
  overflow: auto;
  //overflow-scrolling: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0);
  //z-index: 10000;
}

.choose_stat_type::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(0, 0, 0, 0);
}
.choose_stat_type::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
//.choose_stat_type-webkit-scrollbar-track {
//  -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
//  background-color: #f9f9fd;
//}

$margin_stat_elem_hor: 4%;
$padding_stat_elem: 10%;

.stat_elem {
  width: 100% - $padding_stat_elem;
  min-height: 45px;
  //height: 200px;
  margin: 1.5% 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  padding-left: $padding_stat_elem;

  font-weight: bold;
  font-family: $openSans;
  font-size: 16px;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    //box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.03);
    //color: $ORANGE_500;
  }
}

.active_stat_elem {
  color: $BLUE_500;
  border-radius: 0 20px 20px 0;
  background-color: rgba(74, 87, 255, 0.08);

  &:hover {
    background-color: rgba(74, 87, 255, 0.08);
  }
}

$margin_content_top: 50px;

.stat_header {
  position: fixed;
  top: $header_height;
  height: 100%;
  min-height: $margin_content_top;
  max-height: $margin_content_top;
  left: $margin_content_left + 5px;
  padding-left: 20px;
  padding-right: 20px;
  //padding-top: 10px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;//$GRAY_50;//rgba(0,0,0,0.5);
  //background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 10;

  box-shadow: 10px 0 10px rgba(0,0,0,0.1);
}

.stat_time {
  //background-color: #58FFB8;
  font-size: 18px;
  font-family: $openSans;
}

.stat_header_section_name {
  font-size: 22px;
  font-family: $openSans;
  font-weight: bold;
  //color: white;
}

.main_content {
  left: $margin_content_left;
  //padding-left: 30px;
  top: $margin_content_top + $header_height;
  background-color: $GRAY_50;//white;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: auto;
  //z-index: 1;
}

.main_content::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0.0);
}

.main_content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.graphics_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.graphic_elem {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.graphics_line {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.div_loading {
  width: 90%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.classroom_div, .classroom_list_header_text,
.student_item_div, .modal_error_and_button_div, .student_list_header_text {
  font-size: 18px;
  font-family: $openSans;
  font-weight: bold;
  color: $BLUE_400;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.student_list_header_text {
  margin: 5px 5%;
  width: 90%;
  min-height: 22px;
  color: $BLUE_500;
  font-size: 20px;
}

.student_item_div {
  font-size: 14px;
  text-align: left;
  align-items: flex-start;

  border-radius: 20px;
  padding: 8px 4px 8px 12px;
  width: 80%;
  margin: 2% 10%;

  cursor: pointer;
  background-color: white;

  transition:
          color 180ms ease-out,
          border-color 180ms ease-out,
          background-color 180ms ease-out;

  &:hover {
    background-color: $BLUE_200;
    color: $BLUE_500;
  }
}

.student_item_div_active {
  background-color: $ORANGE_200;
  color: $BLUE_500;

  &:hover {
    background-color: $ORANGE_200;
    color: $BLUE_500;
  }
}

.classroom_list_header_text {
  min-height: 30px;
  //min-width: 90px;
  width: 90%;
  margin: 5%;
  color: $BLUE_500;
  font-size: 24px;
}

.classroom_div, .student_in_choose_list {
  min-height: 60px;
  min-width: 90px;
  width: 90%;
  margin: 5%;

  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: $BLUE_400;

  cursor: pointer;
  background-color: white;

  transition:
          color 180ms ease-out,
          border-color 180ms ease-out,
          background-color 180ms ease-out;

  &:hover {
    background-color: $ORANGE_200;
    border-color: $BLUE_500;
    color: $BLUE_500;
  }
}

.classroom_div_active {
  border-color: $ORANGE_500;
  color: $BLUE_500;
  border-width: 2px;
  background-color: $GRAY_50;

  &:hover {
    background-color: white;
    border-color: $ORANGE_500;
    color: $BLUE_500;
  }
}

.black_div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal_error_and_button_div {
  width: 100%;
}

.modal_inner_error {
  text-align: center;
  color: $RED_500;
  font-size: 14px;
  margin-bottom: 14px;
  min-height: 20px;
}

.input_student_div, .students_choose_list, .student_in_choose_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.students_modal_choose_div {
  position: relative;
  width: 100%;
  padding: 5px;
  //background-color: #58FFB8;
}

.students_choose_list {
  position: absolute;
  top: 0;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  max-height: 200px;
  background-color: rgba(0, 0, 0, 0.3);
  //border-radius: 20px;
}

.students_choose_list::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(0, 0, 0, 0);
}
.students_choose_list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.student_in_choose_list {
  cursor: pointer;
  min-height: 35px;
  width: 90%;
  margin: 3px 5%;
}

.add_classroom_modal_useful_div, .modal_students_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 200px;
  min-width: 500px;
}

.modal_tests_list {
  height: 300px;
  min-height: 300px;
  max-height: 300px;
}

.modal_students_list {
  background-color: white;
  min-height: 100px;
  height: 140px;
  overflow: auto;
  margin: 6px;
}

.modal_students_list::-webkit-scrollbar {
  width: 7px;
  background-color: rgba(0, 0, 0, 0);
}
.modal_students_list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal_student {
  width: 90%;
  margin: 3px 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
}

.modal_student_delete, .modal_student_name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: $openSans;
  font-size: 16px;
  font-weight: bold;
  color: $BLUE_500;
}

.modal_student_delete {
  cursor: pointer;
  text-decoration: none;
  color: $RED_500;
  font-family: $openSans;
  font-size: 14px;

  transition: text-decoration 180ms ease-out;
  &:hover {
    text-decoration: underline;
  }
}

.div_tests_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
}

.test_white_block_div {
  max-height: 90px;
  width: 80%;
  margin: 10px 10%;
  min-width: 200px;
  max-width: 600px;

  background-color: white;
  border-radius: 20px;
  cursor: pointer;

  box-shadow: 0 5px 10px rgba(0,0,0,0.1);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.blue_border {
  border-style: solid;
  border-width: 1px;
  border-color: $BLUE_500;
  color: $BLUE_500;
  justify-content: center;
  align-items: center;
}

.blue_color {
  font-size: 22px;
  font-weight: bold;
  color: $BLUE_500;
}