$color_text: #2e3546;

$color_red: rgba(253, 0, 6, 1);
$color_red_light: #e26163;
//$color_red_light: #FE4044;
$color_red_light_2: #FE7276;

//$color_green: #0BD500;
//$color_green_light: #43EA3A;
//$color_green_light_2: #70EA69;

$color_black: black;
$color_grey: #686868;
$color_white: white;

// COLORS FROM == https://colorscheme.ru/#0r31Ow0w0w0w0 == HERE:
// =============== USE IT ====================

$ORANGE_500: #FF9900;
$ORANGE_400: #FFBA3F;
$ORANGE_300: #FFCE66;
$ORANGE_200: #FFE299;

$RED_500: #FF675B;
$RED_400: #FF9783;
$RED_300: #FFB49C;
$RED_200: #FFD2BD;

$BLUE_500: #4a5784;
$BLUE_400: #7988B5;
$BLUE_300: #A0B0DA;
$BLUE_200: #C8D4F2;

$GRAY_500: #2F4858;
$GRAY_400: #647E8F;
$GRAY_300: #AFC1CC;
$GRAY_200: #e6eef2;
$GRAY_50: #f5fafc;

$BACKGROUND_COLOR: #f5fafc;

$GREEN_200: #58FFB8;
$GREEN_500: #00D077;
$PINK_200: #FABFC5;
$PINK_500: #D6578B;